* {
  user-select: none;
  -ms-user-select: none;
  outline: 0;
  margin: 0;
  padding: 0;
  -webkit-tab-highlight-color: rgba(0, 0, 0, 0);
}

html {
  width: 100%;
  height: 100%;
}

/* default colors */
body {
  --system-text: rgb(0, 0, 0);

  --system-blue: rgb(0, 122, 255);
  --system-green: rgb(52, 199, 89);
  --system-indigo: rgb(88, 86, 214);
  --system-orange: rgb(255, 149, 0);
  --system-pink: rgb(255, 45, 85);
  --system-purple: rgb(175, 82, 222);
  --system-red: rgb(255, 59, 48);
  --system-teal: rgb(90, 200, 250);
  --system-yellow: rgb(255, 204, 0);

  --system-gray: rgb(142, 142, 147);
  --system-gray2: rgb(174, 175, 178);
  --system-gray3: rgb(199, 199, 204);
  --system-gray4: rgb(209, 209, 214);
  --system-gray5: rgb(229, 229, 234);
  --system-gray6: rgb(242, 242, 247);

  font-family: "Montserrat", sans-serif;
}

/* dark theme colours */
body.dark-theme {
  --system-text: rgb(255, 255, 255);

  --system-blue: rgb(10, 132, 255);
  --system-green: rgb(48, 209, 88);
  --system-indigo: rgb(94, 92, 230);
  --system-orange: rgb(255, 159, 10);
  --system-pink: rgb(255, 55, 95);
  --system-purple: rgb(191, 90, 242);
  --system-red: rgb(255, 69, 58);
  --system-teal: rgb(100, 210, 255);
  --system-yellow: rgb(255, 214, 10);

  --system-gray: rgb(142, 142, 147);
  --system-gray2: rgb(99, 99, 102);
  --system-gray3: rgb(72, 72, 74);
  --system-gray4: rgb(58, 58, 60);
  --system-gray5: rgb(44, 44, 46);
  --system-gray6: rgb(28, 28, 30);
}

@media (prefers-color-scheme: dark) {
  /* defaults to dark theme */
  body {
    --system-text: rgb(255, 255, 255);

    --system-blue: rgb(10, 132, 255);
    --system-green: rgb(48, 209, 88);
    --system-indigo: rgb(94, 92, 230);
    --system-orange: rgb(255, 159, 10);
    --system-pink: rgb(255, 55, 95);
    --system-purple: rgb(191, 90, 242);
    --system-red: rgb(255, 69, 58);
    --system-teal: rgb(100, 210, 255);
    --system-yellow: rgb(255, 214, 10);

    --system-gray: rgb(142, 142, 147);
    --system-gray2: rgb(99, 99, 102);
    --system-gray3: rgb(72, 72, 74);
    --system-gray4: rgb(58, 58, 60);
    --system-gray5: rgb(44, 44, 46);
    --system-gray6: rgb(28, 28, 30);
  }
  /* Override dark mode with light mode styles if the user decides to swap */
  body.light-theme {
    --system-text: rgb(0, 0, 0);

    --system-blue: rgb(0, 122, 255);
    --system-green: rgb(52, 199, 89);
    --system-indigo: rgb(88, 86, 214);
    --system-orange: rgb(255, 149, 0);
    --system-pink: rgb(255, 45, 85);
    --system-purple: rgb(175, 82, 222);
    --system-red: rgb(255, 59, 48);
    --system-teal: rgb(90, 200, 250);
    --system-yellow: rgb(255, 204, 0);

    --system-gray: rgb(142, 142, 147);
    --system-gray2: rgb(174, 175, 178);
    --system-gray3: rgb(199, 199, 204);
    --system-gray4: rgb(209, 209, 214);
    --system-gray5: rgb(229, 229, 234);
    --system-gray6: rgb(242, 242, 247);
  }
}

body {
  cursor: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--system-gray5);
  transition: all 0.5s ease;
}

a {
  color: var(--system-text);
  text-decoration: none;
  cursor: none;
}

a:hover {
  color: var(--system-teal);
}

nav {
  color: var(--system-text);
}

.hidden {
  display: none;
}

.visuallyhidden {
  opacity: 0;
}

.logo {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 36px;
  font-weight: 200;
}

.about-btn {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: 12px;
  transform: rotate(-90deg);
  font-size: 18px;
  font-weight: 200;
  animation-name: animateright;
  animation-duration: 0.4s;
}

.instruction {
    position: absolute;
    fill: var(--system-text);
    transition: all 0.5s ease;
}

.instruction:after {
  content: "Swipe";
  color: #ffffff;
  font-size: 20px;
}

.instruction__svg {
  height: 96px;
  width: 96px;
}

footer {
  position: absolute;
  top: 100px;
  left: 20px;
  animation-name: animateright;
  animation-duration: 0.4s;
}

.cursor {
  position: absolute;
  color: var(--system-text);
  border: 5px solid var(--system-text);
  height: 20px;
  width: 20px;
  outline: 2px solid var(--system-gray);
  z-index: 9999;
  pointer-events: none;
}

.cursor--active {
  background-color: var(--system-text);
  border: 5px solid var(--system-teal);
}

.cursor--center {
  position: absolute;
}

.cursor--up {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: animatebottom;
  animation-duration: 0.4s;
}

.cursor__arrow-up {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--system-gray);
  transition: all 0.5s ease;
  animation-name: animatebottom;
  animation-duration: 0.4s;
}

.cusor__arrow-up--active {
  border-bottom: 8px solid var(--system-teal);
}

.cursor--down {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.cursor__arrow-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--system-gray);
  transition: all 0.5s ease;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.cusor__arrow-down--active {
  border-top: 8px solid var(--system-teal);
}

.cursor--left {
  white-space: nowrap;
  direction: ltl;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  animation-name: animateright;
  animation-duration: 0.4s;
}

.cursor__arrow-left {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid var(--system-gray);
  transition: all 0.5s ease;
  animation-name: animateright;
  animation-duration: 0.4s;
}

.cusor__arrow-left--active {
  border-right: 8px solid var(--system-teal);
}

.cursor--right {
  white-space: nowrap;
  direction: rtl;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  animation-name: animateleft;
  animation-duration: 0.4s;
}

.cursor__arrow-right {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--system-gray);
  transition: all 0.5s ease;
  animation-name: animateleft;
  animation-duration: 0.4s;
}

.cusor__arrow-right--active {
  border-left: 8px solid var(--system-teal);
}

.ui__btn-toggle {
  position: absolute;
  top: 5%;
  right: 10%;
}

canvas {
  width: 100%;
  height: 100%;
}

.neumorphism--text {
  text-shadow: -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 4px rgba(0, 0, 0, 0.08);
  caret-color: #262626;
  outline: none;
}

.neumorphism--icon {
  border-radius: 5px;
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.social-icons__svg {
  height: 24px;
  width: 24px;
}

.social-icons__btn {
  padding: 5px;
}

.social-icons__link--youtube {
  fill: var(--system-text);
}

.social-icons__link--youtube:hover {
  fill: var(--system-teal);
}

.social-icons__link--instagram {
  fill: var(--system-text);
}

.social-icons__link--instagram:hover {
  fill: var(--system-teal);
}

.about {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
  background-position: bottom;
  transition: all 0.5s linear;
  z-index: 999;
}

.about__contents {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.1fr 0.5fr 0.25fr 0.2fr 2fr 0.25fr 0.1fr;
  grid-template-areas:
    "about_copyright . ."
    "about__contents__title about__contents__title ."
    "about__contents__subtitle about__contents__subtitle about__contents__subtitle"
    ". . ."
    ". about__contents__body1 about__contents__body2"
    ". . ."
    "about__contents__contact about__contents__contact about__contents__contact";
  column-gap: 20px;

  color: #000000;
  position: absolute;
  padding: 50px;
  width: 80%;
  max-width: 800px;
  left: 50%;
  transform: translate(-50%);
  -ms-transform: translate(-50%);
  background-color: #ffffff;
  transition: all 0.5s linear;
  animation-name: animatebottom;
  animation-duration: 0.4s;
}

.about__contents a {
  color: #000000;
}

.about__contents a:hover {
  color: var(--system-teal);
}

.about__close {
  position: absolute;
  top: 10%;
  right: 5%;
  font-size: 28px;
  font-weight: 900;
}

.about__close:hover {
  color: var(--system-teal);
}

.about__contents__title {
  grid-area: about__contents__title;
  /* background: #00ff00; */
  font-size: 100px;
  font-weight: 200;
}

.about__contents__subtitle {
  grid-area: about__contents__subtitle;
  font-size: 1em;
  padding-left: 10px;
}

.about__contents__body1 {
  grid-area: about__contents__body1;
  /* background: #ff0000; */
}

.about__contents__body2 {
  grid-area: about__contents__body2;
  /* background: #ff00ff; */
}

.about__contents__contact {
  grid-area: about__contents__contact;
  /* background: #0000ff; */
}

.about_copyright {
  grid-area: about_copyright;
  font-size: 10px;
}

.about__contents__contact__elem {
  padding: 10px;
}

/* keyframes */
@keyframes animatetop {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animateleft {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .about__contents {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 0.5fr 0.25fr 0.2fr 1.5fr 0.1fr 1.5fr 0.1fr;
    grid-template-areas:
      "about_copyright"
      "about__contents__title"
      "about__contents__subtitle"
      "."
      "about__contents__body1"
      "."
      "about__contents__body2"
      "about__contents__contact";
  }

  .about__contents__subtitle {
    grid-area: about__contents__subtitle;
    font-size: 1em;
    padding-left: 0px;
  }
  
}